import React from 'react';
import ModelCanvas from '../components/ModelCanvas';
import Layout from '../components/Layout';
import Button from '../components/Button';

export default function indexPage() {
  const isBrowser = typeof window !== 'undefined';

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Atrial Septal Defect</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner model">
            <section>
              <div className="row">
                <div className="col-6 col-12-medium">
                  <h2>Atrial Septal Defect</h2>
                  <p>
                    is one of the most common types of congenital heart defects,
                    occurring in about 25% of children. Defect between the atria
                    allows abnormal flow of oxygen-rich blood to leak into the
                    oxygen-poor blood chambers in the heart. Small atrial septal
                    defects usually spontaneously close in childhood. Large
                    defects that do not close spontaneously may require
                    percutaneous or surgical intervention to prevent further
                    complications such as stroke, dysrhythmias, and pulmonary
                    hypertension. The indication for closure of the defect is
                    the presence of right ventricular volume overload.
                  </p>
                </div>
                {isBrowser && (
                  <div className="col-6 col-12-medium">
                    <ModelCanvas
                      model_path={'/atrial-septal-defect-cut.glb'}
                      camera_position={[5, 5, 5]}
                      light_intensity={0.4}
                      group_rotation={[-0.0, -0.1, 0.1]}
                      group_position={[-0.1, -0.9, 0.5]}
                      group_scale={[10, 10, 10]}
                    />
                  </div>
                )}
              </div>
            </section>
            <div>
              <br />
              <ul className="actions stacked">
                <li>
                  <br />
                  <Button
                    buttonClass="button small primary"
                    url="https://biovoxel.tech/en/services/organ-models/"
                    text="Print 3D Model"
                    action="print"
                  />{' '}
                  <Button
                    buttonClass="button icon solid fa-coffee"
                    url="https://buymeacoff.ee/3DHearter"
                    text="^^"
                    action="donate"
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
}
